//
// Footer
//

.bd-footer {
  a {
    color: $gray-700;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $link-color;
      text-decoration: underline;
    }
  }
}
