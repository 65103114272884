.anchor-link {
  padding: 0 .175rem;
  font-weight: 400;
  color: rgba($link-color, .5);
  text-decoration: none;
  opacity: 0;
  @include transition(color .15s ease-in-out, opacity .15s ease-in-out);

  &::after {
    content: "#";
  }

  &:focus,
  &:hover,
  :hover > &,
  :target > & {
    color: $link-color;
    text-decoration: none;
    opacity: 1;
  }
}
