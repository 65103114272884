//
// Bootstrap docs content theming
//

.bd-content {
  // Offset content from fixed navbar when jumping to headings
  > :target {
    padding-top: 5rem;
    margin-top: -5rem;
  }

  > h2:not(:first-child) {
    margin-top: 3rem;
  }

  > h3 {
    margin-top: 2rem;
  }

  > ul li,
  > ol li {
    margin-bottom: .25rem;

    // stylelint-disable selector-max-type, selector-max-compound-selectors
    > p ~ ul {
      margin-top: -.5rem;
      margin-bottom: 1rem;
    }
    // stylelint-enable selector-max-type, selector-max-compound-selectors
  }

  // Override Bootstrap defaults
  > .table,
  > .table-responsive .table {
    margin-bottom: 1.5rem;
    @include font-size(.875rem);

    @include media-breakpoint-down(lg) {
      &.table-bordered {
        border: 0;
      }
    }

    thead {
      border-bottom: 2px solid currentcolor;
    }

    tbody:not(:first-child) {
      border-top: 2px solid currentcolor;
    }

    th,
    td {
      &:first-child {
        padding-left: 0;
      }

      &:not(:last-child) {
        padding-right: 1.5rem;
      }
    }

    // Prevent breaking of code
    // stylelint-disable-next-line selector-max-compound-selectors
    th,
    td:first-child > code {
      white-space: nowrap;
    }
  }
}

.table-options {
  td:nth-child(2) {
    min-width: 160px;
  }
}

.table-options td:last-child,
.table-utilities td:last-child {
  min-width: 280px;
}

.summary {
  @include font-size(1.5rem);
  font-weight: 300;
}

.bd-bg-violet {
  background-color: $bd-violet;
}

.bi {
  width: 1em;
  height: 1em;
  fill: currentcolor;
}

.icon-link {
  display: flex;
  align-items: center;
  text-decoration-color: rgba($primary, .5);
  text-underline-offset: .5rem;
  backface-visibility: hidden;

  .bi {
    width: 1.5em;
    height: 1.5em;
    transition: .2s ease-in-out transform; // stylelint-disable-line property-disallowed-list
  }

  &:hover {
    .bi {
      transform: translate3d(5px, 0, 0);
    }
  }
}

.border-lg-start {
  @include media-breakpoint-up(lg) {
    border-left: $border-width solid $border-color;
  }
}
