.bd-navbar {
  padding: .75rem 0;
  background-color: transparent;
  background-image: linear-gradient(to bottom, rgba(var(--bs-primary-rgb), 1), rgba(var(--bs-primary-rgb), .95));
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15), inset 0 -1px 0 rgba(0, 0, 0, .15);

  .bd-navbar-toggle {
    @include media-breakpoint-down(lg) {
      width: 4.25rem;
    }
  }

  .navbar-toggler {
    padding: 0;
    margin-right: -.5rem;
    border: 0;

    &:first-child {
      margin-left: -.5rem;
    }

    .bi {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-brand {
    transition: .2s ease-in-out transform; // stylelint-disable-line property-disallowed-list

    &:hover {
      transform: rotate(-5deg) scale(1.1);
    }
  }

  .navbar-toggler,
  .nav-link {
    padding-right: $spacer * .25;
    padding-left: $spacer * .25;
    color: rgba($white, .85);

    &:hover,
    &:focus {
      color: $white;
    }

    &.active {
      font-weight: 600;
      color: $white;
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    vertical-align: -.125rem;
  }

  .offcanvas-lg {
    background-color: var(--bd-violet);
    border-left: 0;

    @include media-breakpoint-down(lg) {
      box-shadow: $box-shadow-lg;
    }
  }

  .dropdown-toggle {
    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .dropdown-menu {
    --#{$prefix}dropdown-min-width: 12rem;
    --#{$prefix}dropdown-link-hover-bg: rgba(var(--bd-bg-light-rgb), .1);
    @include rfs(.875rem, --#{$prefix}dropdown-font-size);
    box-shadow: $dropdown-box-shadow;
  }

  .dropdown-item.current {
    font-weight: 600;
    background-image: escape-svg($dropdown-active-icon);
    background-repeat: no-repeat;
    background-position: right $dropdown-item-padding-x top .6rem;
    background-size: .75rem .75rem;
  }
}
