// stylelint-disable scss/dollar-variable-default

// Local docs variables
$bd-bg:        #003C64;
$bd-bg-light:        lighten(saturate($bd-bg, 5%), 15%); // stylelint-disable-line function-disallowed-list
$bd-bg-lighter:  lighten(saturate($bd-bg, 5%), 45%); // stylelint-disable-line function-disallowed-list
$bd-purple:        #4c0bce;
$bd-violet:        #167610; // stylelint-disable-line function-disallowed-list
$bd-purple-light:  lighten(saturate($bd-bg, 5%), 45%); // stylelint-disable-line function-disallowed-list
$bd-accent:       #ffe484;
$dropdown-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");

$bd-gutter-x: 3rem;
$bd-callout-variants: info, warning, danger !default;

:root {
  --bd-purple: #{$bd-purple};
  --bd-violet: #{$bd-violet};
  --bd-accent: #{$bd-accent};
  --bd-bg-light-rgb: #{to-rgb($bd-bg-light)};
  --bd-violet-rgb: #{to-rgb($bd-violet)};
  --bd-accent-rgb: #{to-rgb($bd-accent)};
  --bd-pink-rgb: #{to-rgb($pink-500)};
  --bd-teal-rgb: #{to-rgb($teal-500)};
  --docsearch-primary-color: var(--bd-violet);
  --docsearch-logo-color: var(--bd-violet);
}
