// stylelint-disable scss/dollar-variable-default
@font-face {
  font-family: 'biome';
  src: url("/apos-frontend/default/modules/asset/fonts/Biome_W04_Light.ttf") format("truetype");
}

$font-family-base:                                          "biome", sans-serif !default;

$headings-line-height:        1.5;
$line-height-base:            1.3;
$line-height-sm:              1.2;
$line-height-lg:              1.8;

// Local docs variables
$body-color: #003C64;

$primary:        #003C64;
$navbar-light-active-color: #FFFFFF;
// $light:         #f3faf3;
$headings-color: $primary;
